
//  Function to simulate dynamic group by functionality

// EX Call
// console.log(groupBy(['one', 'two', 'three'], 'length'));
export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

// EX Call
// const grouped = groupBy(pets, pet => pet.type);
// console.log(grouped.get("Dog"));

export function groupBy2(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}


// -----------------------------------------------