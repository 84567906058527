import { restPost } from "./RestClient";

export async function fetchFeatures() {
	const res = await restPost(`marketing/FetchFeatures`, {});
	return res.data;
}

export async function fetchFAQ() {
	const res = await restPost(`marketing/FetchFAQ`, {});
	return res.data;
}