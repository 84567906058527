import Bulk01 from "./../assets/images/bulk//bulk01.png";
import BuildIdLogo from "./../assets/images/BuildIdLogo.png";
import playStore from "./../assets/images/playStore.png";
import appleStore from "./../assets/images/appleStore.png";
//Home Page Graphics
import map from "./../assets/images/Sites/maps.png";
import connect from "./../assets/images/Sites/connect.png";
import site from "./../assets/images/Sites/sites.png";
// import site from "./../assets/images/site.png";
import safetytickets from "./../assets/images/Safety tickets.png";
// import safetytickets from "./../assets/images/safety-tickets.jpg";
import resume from "./../assets/images/Resume.png";
// import resume from "./../assets/images/construction-resume.jpg";
import EarnBuilderBucks from "./../assets/images/BuilderBucks.png";
// import EarnBuilderBucks from "./../assets/images/earn-builderbucks.jpg";
import "../assets/css/Home.css"

export default function Home() {
  return (
    <>
      <section className="heading-section bulk-sms padding-top padding-bottom homepage-altcol-1">
        <div className="container" >
          <div className="home-container row flex-wrap-reverse align-items-center" >
            {/* <div className="col-lg-7"> */}
            <div
              className="bulk-content text-center text-sm-left wow fadeIn"
              data-wow-delay=".3s">
              <div className="first-section-logo">
                <div className="hero-title" >
                  <div style={{ display: "flex", justifyContent: "center", margin: "6vh 0 6vh 0" }}>
                    <div className="store-icon">
                      <img src={BuildIdLogo} alt="logo" style={{ borderRadius: "8px" }} />
                    </div>
                    <h2 style={{ margin: "auto 24px auto 24px" }}>Be part of the Builder Community </h2>
                  </div>

                  <h6 className="description">BuildID is a tool for tradespeople and others working in the construction industry that take pride in building the structures that shape our world.
                    We want to create a construction community that empowers and rewards all members. </h6>
                </div>
              </div>
              <div className="download-btn" >
                <a
                  href="https://play.google.com/store/apps/details?id=com.mybuilderid&pli=1"
                  className="store-icon">
                  <img src={playStore} alt="logo"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/build-id/id1490164104"
                  className="store-icon">
                  <img src={appleStore} alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bulk-sms padding-top padding-bottom theme-dark homepage-altcol-1">
        <div /*className="container"*/ style={{ textAlign: "center", width: '100%', padding: '1vw' }}>
          <h2
            className="title dark-theme-title"
            style={{ marginBottom: "30px", fontSize: "40px" }}>
            Explore construction sites
          </h2>
          <p>
            Use the interactive map to find and view details about the sites
            you’ve worked at – or want to work at.
          </p>

          <div className="site-card-container">
            <div className="site-card">
              <img src={map} alt="map alt" />
              <div className="site-card-text">
                <h3>Maps</h3>
                <p>View work sites and construction projects in your area, or anywhere in the world! See where fellow builders are actively engaged and showcase your own past and current work sites and projects.</p>
              </div>
            </div>

            <div className="site-card">
              <img src={connect} alt="connect alt" />
              <div className="site-card-text">
                <h3>Connect</h3>
                <p>Stay connected with every builder you encounter, from trades on your work site, to builders you worked with multiple projects ago. Strengthen your professional relationships, gain expert skills, and streamline communication, as you grow your network to include tradespeople from all throughout your career.</p>
              </div>
            </div>

            <div className="site-card">
              <img src={site} alt="site alt" />
              <div className="site-card-text">
                <h3>Sites</h3>
                <p>Sites are the physical locations where construction projects are taking place or have been completed. A construction site may have multiple projects recorded on it, past or present. There's so much to discover on every site you find!</p>
              </div>
            </div>
          </div>

          {/* <div className="row flex-wrap-reverse">
            <div className="col-lg-4 col-md-4 col-sm-12 ">


            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">

              <img src={site} className="explore-img" alt="bulk" />
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12">

              <img src={connect} className="explore-img" alt="bulk" />
            </div>
          </div> */}
        </div>
      </section>

      <section className="bulk-sms padding-top padding-bottom homepage-altcol-2">
        <div className="container" >
          <div className="row flex-wrap-reverse align-items-center">
            <div className="col-lg-6">
              <div className="bulk-thumb wow slideInRight">
                <img src={safetytickets} alt="bulk" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="bulk-content text-center text-sm-left wow fadeIn"
                data-wow-delay=".3s">
                <h2 className="title">Add and share your safety tickets</h2>
                <p style={{ paddingBottom: "18px" }}>
                  Keep your managers and others around you updated by sharing
                  your tickets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bulk-sms padding-top padding-bottom homepage-altcol-1">
        <div className="container">
          <div className="row flex-wrap-reverse align-items-center">
            <div className="col-lg-6">
              <div
                className="bulk-content text-center text-sm-left wow fadeIn"
                data-wow-delay=".3s">
                <h2 className="title">Earn BuilderBucks </h2>
                <p style={{ paddingBottom: "18px" }}>
                  Collect BuilderBucks by adding information to BuildID, and
                  redeem them for rewards in our store.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bulk-thumb wow slideInRight">
                <img src={EarnBuilderBucks} alt="bulk" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bulk-sms padding-top padding-bottom homepage-altcol-2">
        <div className="container">
          <div className="row flex-wrap-reverse align-items-center">
            <div className="col-lg-6">
              <div className="bulk-thumb wow slideInRight" >
                <img src={resume} alt="bulk" />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="bulk-content text-center text-sm-left wow fadeIn"
                data-wow-delay=".3s">
                <h2 className="title">Generate professional-looking resumes</h2>
                <p style={{ paddingBottom: "18px" }}>
                  Add your companies, projects, and education to your profile –
                  then export it as a PDF resume.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="bulk-sms padding-top padding-bottom theme-dark homepage-altcol-1">
        <div className="container">
          <h2
            className="title dark-theme-title"
            style={{ marginBottom: "30px" }}>
            Tons More Features
          </h2>
          <div className="row flex-wrap-reverse tons-more-features">
            <div className="col-lg-6">
              <li style={{ listStyle: "none" }}>
                <i className="fa fa-check-circle features-list-icon"></i>Chat
              </li>
              <li style={{ listStyle: "none" }}>
                <i className="fa fa-check-circle features-list-icon"></i>Ride
                Share
              </li>
              <li style={{ listStyle: "none" }}>
                <i className="fa fa-check-circle features-list-icon"></i>
                Timesheets{" "}
              </li>
              <li style={{ listStyle: "none" }}>
                <i className="fa fa-check-circle features-list-icon"></i>Expense
                Tracking
              </li>
            </div>
            <div className="col-lg-6">
              <li style={{ listStyle: "none" }}>
                <i className="fa fa-check-circle features-list-icon"></i>Montage
                Creator
              </li>
              <li style={{ listStyle: "none" }}>
                <i className="fa fa-check-circle features-list-icon"></i>Network
                with tradespeople{" "}
              </li>
              <li style={{ listStyle: "none" }}>
                <i className="fa fa-check-circle features-list-icon"></i>And
                more…{" "}
              </li>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
