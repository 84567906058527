import React from "react";

import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo/logo01.png";
function Header() {
    const location = useLocation();
    const [isActive, setActive] = React.useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    return (
        <header style={{ backgroundColor: "white", padding: "0px 10px 0px 10px", marginBottom: "auto" }}>
            <div className="header-bottom">
                <div className="header-area" >
                    <div className="logo">
                        <NavLink exact to="/Campaign-Home">
                            <img src={logo} alt="logo" style={{width:"24vmax"}}/>
                        </NavLink>
                    </div>

                    <div className="hamburger-container">
                        <ul className={isActive ? 'menu active' : 'menu'}>
                            <li>
                                <NavLink
                                // end
                                // to="/about"
                                // className={
                                //     location.pathname === "/about" ? "active-tab" : ""
                                // }
                                // onClick={toggleClass}
                                >

                                    Current Campaigns
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    // end
                                    // to="/features"
                                    // className={location.pathname === "/features" ? "active-tab" : ""}
                                    // onClick={toggleClass}
                                >
                                    Previous Campaigns
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    // end
                                    // to="/faqs"
                                    // className={location.pathname === "/faqs" ? "active-tab" : ""}
                                    // onClick={toggleClass}
                                >
                                    Pending Campaigns
                                </NavLink>
                            </li>
                        </ul>
                        <div style={{marginRight:"12px"}}className={isActive ? 'header-bar d-lg-none active' : 'header-bar d-lg-none'} onClick={toggleClass}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
}

export default Header;
