import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo/logo01.png";
import "../../assets/css/Home.css"

function Header() {
  const location = useLocation();
  const [isActive, setActive] = React.useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <header>
      <div className="header-bottom" >
        <div className="container">
          <div className="header-area">
            <div className="logo" style={{ width: "24vmax" }}>
              <NavLink end to="/">
                <img width="100%" src={logo} alt="logo" />
              </NavLink>
            </div>
            <ul className={isActive ? 'menu active' : 'menu'}>
              <li>
                <NavLink
                  end
                  to="/about"
                  className={
                    location.pathname === "/about" ? "active-tab" : ""
                  }
                  onClick={toggleClass}>

                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  to="/features"
                  className={location.pathname === "/features" ? "active-tab" : ""}
                  onClick={toggleClass}>
                  Features
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  to="/faqs"
                  className={location.pathname === "/faqs" ? "active-tab" : ""}
                  onClick={toggleClass}>
                  FAQs
                </NavLink>
              </li>
              <li>
                <NavLink
                  end
                  to="/contact"
                  className={
                    location.pathname === "/contact" ? "active-tab" : ""
                  }
                  onClick={toggleClass}>
                  Contact
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="https://user.mybuilderid.com/" // The URL you want to redirect to
                >
                  Login
                </NavLink>
              </li>
              {/* <li className="login-button">
                <Button
                  // themeColor={'secondary'}
                  // style={{ marginBottom: "10px" }}
                >
                  <NavLink
                    end
                    to={localStorage.getItem("token") ? "/Campaign-Home" : "/login"}
                    className={
                      location.pathname === "/login" ? "active-tab" : ""
                    }
                    onClick={toggleClass}
                  >
                    Create Advertisements
                  </NavLink>
                </Button>

              </li> */}
            </ul>
            <div className={isActive ? 'header-bar d-lg-none active' : 'header-bar d-lg-none'} onClick={toggleClass}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
